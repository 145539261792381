import './set-public-path'

import React from 'react'
import ReactDOM from 'react-dom'
import Root from './components/Root'
import singleSpaReact from 'single-spa-react'

export const { bootstrap, mount, unmount } = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    // https://reactjs.org/docs/error-boundaries.html
    console.error(err, info, props)
    return React.createElement('div')
  },
})
