export const localesRU = {
  commonLocale: {
    header: 'Вход в систему',
    techSupport: 'Техническая поддержка Netvision',
  },
  formLocale: {
    username: 'Имя пользователя',
    password: 'Пароль',
    service: 'Организация',
    useDefaultService: 'Организация по-умолчанию',
    submit: 'Войти',
    enterUsername: 'Введите имя пользователя',
    enterPassword: 'Введите пароль',
    enterService: 'Введите организацию',
    loading: 'Авторизация...',
    success: 'Успешная авторизация',
    wrong_data: 'Неверные данные',
    server_error: 'Ошибка сервера',
    unexpected_error: 'Непредвиденная ошибка',
    loginWith: 'Войти с помощью',
  },
  licenseLocale: {
    expired: 'Лицензия невалидна или истек срок ее действия. Обратитесь в тех.поддержку',
    expiring: 'Дней до истечения лицензии: {param}',
  },
}
