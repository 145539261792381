import React, { FC, ReactNode, useContext, useEffect, useLayoutEffect, useState } from 'react';
import { locales, ILocale } from '../locales';
import { get } from 'lodash-es'

export type LocaleCodes = 'ru' | 'en'

interface ILocaleContext {
  locale: LocaleCodes;
  setLocale: (locale: LocaleCodes) => void
  $t: (path: string, ...rest: Array<string | number>) => string;
}

const LocaleContext = React.createContext<ILocaleContext>({
  locale: 'ru',
  setLocale: (locale: LocaleCodes) => {},
  $t: (path: string, ...rest: Array<string | number>): string => path,
});

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider: FC<{ value?: ILocale, children: ReactNode }> = ({value = locales, children}) => {
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [locale, setLocale] = useState<LocaleCodes>('ru')
  const $t = (path: string, ...rest: Array<string | number>) => {
    const message: string = String(get(value, `${locale}.${path}`))
    return !rest.length ?
      message :
      message.split(' ').reduce((acc, next) => {
        acc.push(
          next === '{param}' ?
            String(rest.shift()) : 
            next
        )
        return acc
      }, [] as Array<string | number>).join(' ')
  }

  useLayoutEffect(() => {
    if (isFirstRender) {
      setLocale(localStorage.getItem('netvision:locale') as LocaleCodes || 'ru')
      setTimeout(() => setIsFirstRender(false), 0)
    }
  }, [isFirstRender])

  useEffect(() => {
    if (!isFirstRender && locale) {
      localStorage.setItem('netvision:locale', locale)
    }
  }, [locale])

  return (
    <LocaleContext.Provider value={{ $t, locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
};
